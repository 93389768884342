import AOS from 'aos';

import './_titleAnimation.js';
import './_menu.js';
import './_parallax.js';
import './_workpage.js';
import './_ajaxForm.js';


{
  const init = () => {
    AOS.init({
      disable: 'phone',
      easing: 'ease-in-out',
    });
  };

  init();
}
