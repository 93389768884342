{

  let projects = [];

  const handleSelectProject = e => {
    const selected = e.currentTarget;
    console.log('there was a project clicked', selected.dataset.title);

    updateUrl(selected.id);


    setActiveData(selected.dataset.image, selected.dataset.title, selected.dataset.labels, selected.dataset.content)
  }

  const updateUrl = project => {
    if (history.pushState) {
      var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?project=' + project;
      window.history.pushState({path:newurl},'',newurl);
    }
  }

  const setActiveData = (image, title, labels, content) => {
    document.getElementById('active-image').src = image;
    document.getElementById('active-title').innerHTML = title;
    document.getElementById('active-labels').innerHTML = labels;
    document.getElementById('active-content').innerHTML = content;
  }

  const handleFilterProjects = e => {
    // prevent pageload
    e.preventDefault();
    const selected = e.currentTarget;

    const filter = selected.dataset.filter;

    console.log('CHECK', filter);

    projects.forEach(project => project.classList.add('hidden'));

    projects.forEach(project => {
      if (project.dataset.filter.toLowerCase().includes(filter)) {
        project.classList.remove('hidden');
      }
    });

    if (filter === 'recent') {
      projects.forEach(project => project.classList.remove('hidden'));
    }

  }

  const init = () => {
    if (document.querySelector(`.workpage`)) {
      const url = new URL(window.location.href);
      const urlProject = url.searchParams.get("project");

      if(urlProject) {
        const activeProject = document.getElementById(urlProject);

        setActiveData(activeProject.dataset.image, activeProject.dataset.title, activeProject.dataset.labels, activeProject.dataset.content)
      }

      projects = Array.from(document.querySelectorAll(`.work__list__item`));
      projects.forEach(project => project.addEventListener(`click`, handleSelectProject));

      const filters = Array.from(document.querySelectorAll(`.filters__link`));

      filters.forEach(filter => filter.addEventListener(`click`, handleFilterProjects))
    }
  }

  init();
}