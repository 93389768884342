{

  const splitText = (position, text) => {
    const parts = text.split(' ');

    pushToDom(position, parts);
  }

  const pushToDom = (pos, parts) => {
    const place = document.getElementById(pos);

    // const htmlFormatted = '';

    //  parts.forEach(part => htmlFormatted.concat(`<span class="title-part">${ part }</span>`));
    place.innerHTML = '';

    parts.forEach(element => {
      element = `<span class="title-element">${ element }</span>`;

      place.innerHTML = place.innerHTML.concat(element + ' ');
    });
  }

  // const randomIntFromInterval = (min, max) => { // min and max included 
  //   return Math.floor(Math.random() * (max - min + 1) + min)
  // }

  // const setImages = images => {
  //   setInterval(function() {
  //     document.getElementById('topTitleImage').style.backgroundImage = `url(${ images[randomIntFromInterval(0, images.length - 1)] })`;

  //     document.getElementById('bottomTitleImage').style.backgroundImage = `url(${ images[randomIntFromInterval(0, images.length - 1)] })`;
  //   }, 3000);
  // }

  const init = () => {
    if (document.getElementById('titleLine1')) {

      // const urls = document.getElementById('imageUrls').dataset.urls.replace(/(\r\n|\n|\r)/gm, "").replace(/ /g,'');
      // const images = urls.split(',');
      // const filteredImages = images.filter(i => i !== '');

      // setImages(filteredImages);

      const line1 = document.getElementById('titleLine1').innerText;
      const line2 = document.getElementById('titleLine2').innerText;
      const line3 = document.getElementById('titleLine3').innerText;

      splitText('titleLine1', line1);
      splitText('titleLine2', line2);
      splitText('titleLine3', line3);

      const titleElements = Array.from(document.querySelectorAll('.title-element'));

      
      titleElements.forEach((el, i) => {
        setTimeout(() => {
          el.classList.add('title-element-visible');
        }, Math.floor(Math.random() * 1000));
      });
    }

  };

  init();
}