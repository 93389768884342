{

  window.addEventListener('scroll', function(e) {

    const target = document.querySelectorAll('.scroll');


    var index = 0, length = target.length;
    for (index; index < length; index++) {
        const currentTarget = target[index];

        const vh = window.innerHeight;
        const targetY = currentTarget.getBoundingClientRect().top;

        // var pos = window.pageYOffset * currentTarget.dataset.rate;
        // const pos = (targetY * currentTarget.dataset.rate)/10;

        const posX = (targetY * currentTarget.dataset.ratex)/30;

        if (targetY <= vh) {

          // vertical parallax effect
          // if(currentTarget.dataset.direction === 'vertical' && this.window.innerWidth > 680) {
          //   currentTarget.style.transform = 'translate3d(0px,'+pos+'px, 0px)';
          // } 

          // horizontal parallax effect
          if (currentTarget.dataset.direction === 'horizontal') {              
            currentTarget.style.transform = 'translate3d(' + posX + 'px, 0px, 0px)';
          }

          // zoom on scroll
          // if(currentTarget.dataset.effect === "zoom") {
          //   let docHeight = docHeight = document.documentElement.offsetHeight;
          //   const zoom = window.scrollY / ( docHeight - window.innerHeight ) + 1;

          //   currentTarget.style.transform = `scale(${zoom})`;
          // }
        }
    }
  });
}