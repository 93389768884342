{

  let menuOpen = false;

  const handleOpenMenu = () => {
    const menu = document.getElementById('menuOverlay');

    menuOpen = !menuOpen;

    menu.classList.toggle('hidden');
    document.querySelector('main').classList.toggle('nav-active');
    document.querySelector('footer').classList.toggle('nav-active');

    if (menuOpen) {
      document.querySelector('body').style.overflowY = "hidden";
      menu.ariaHidden = "false";
    } else {
      document.querySelector('body').style.overflowY = "scroll";
      menu.ariaHidden = "true";
    }
  }

  const init = () => {
    const button = document.getElementById('menuButton');
    button.addEventListener(`click`, handleOpenMenu);
  }

  init();
}